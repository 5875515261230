module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aventon-iPad-Displays","short_name":"AventonIpad","start_url":"/","background_color":"#000000","theme_color":"#dcd932","display":"standalone","icon":"src/images/aventon_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4765c2dc4f29edefa4616ac9d9458de8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/index.html","/sinch","/level","/aventure","/pace-350","/pace-500","/"],"workboxConfig":{"globPatterns":["**/*{.html,.webp,.png,.webmanifest,.woff,.woff2,.ttf,.eot,.css,.svg,.mp3,.mp4,icons/icon*,.ico, sw.js}"],"maximumFileSizeToCacheInBytes":20000000}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    }]
